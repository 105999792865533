@import "../../../assets/scss/partials/mixins";

.pdf-preview-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  width: clamp(rem(1080), get-vw(1100), rem(1100));
  height: 88vh;
  background-color: var(--c-white);
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 17px 12px;

  .close-outer {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    
    .right-corner{
      display: flex;
      gap: 10px;
    }

    span {
      cursor: pointer;
    }
  }

  embed{
    border-radius: 10px;
  }

  .pdf-viewer {
    flex: 1;
  }

  &:focus-visible{
    outline: none;
  }
}
