@import "../../assets/scss/partials/mixins";

.insert-image-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: clamp(rem(680), get-vw(700), rem(700));
  height: 550px;
  background-color: var(--c-white);
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 17px 14px;
  display: flex;
  flex-direction: column;

  .header-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-title {
      font-family: var(--primary-font);
      font-size: 18px;
    }
  }

  .form-content {
    margin: 0px 0px 10px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    overflow-y: auto;

    .tenant-row {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .organization-select {
        width: 250px;
      }
    }

    .org-list-title {
      font-family: var(--primary-font);
      font-size: 14px;
      margin: 5px 10px 0px 5px;
    }

    .org-list-content {
      margin: 0px 5px 5px 10px;
      flex: 1 1;
      display: flex;
      overflow-y: auto;
      flex-direction: row;
      align-content: center;
      flex-wrap: wrap;
    }
  }

  .footer-outer {
    display: flex;
    justify-content: flex-end;
  }

  &:focus {
    outline: none;
  }
}