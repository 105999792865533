html {
    padding: env(safe-area-inset);
    scroll-behavior: smooth;
}

.row_clr {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

* {
    padding: 0;
    margin: 0;
}

.no-pad {
    padding: 0;
}

.tac {
    text-align: center;
}

@media (min-width: 768px) {
    .col-sm-20 {
        width: 20%;
        position: relative;
    }
}

.hid {
    opacity: 0;
}

#__bs_notify__ {
    display: none !important;
}

body {
    font-family: var(--body-font);
    color: var(--c-black-gray);
    --current-color: #251C15;
    --current-color-rgb: rgb(37, 28, 21);
}

.pen {
    pointer-events: none !important;
}

@media (min-width: 576px) {
    .container {
        max-width: clamp(rem(500), get-vw(1400), rem(1400));
        padding: 0;
        &.small-container {
            max-width: clamp(720px, 75vw, 1440px);
        }
    }
}

a,
button {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    border: none;
    outline: none !important;
    background: none;
    text-decoration: none !important;
    box-shadow: none !important;
}

.mb-10 {
    margin-bottom: clamp(rem(5), get-vw(10), rem(10));
}
.mb-20 {
    margin-bottom: clamp(rem(10), get-vw(20), rem(20));
}
.mb-30 {
    margin-bottom: clamp(rem(15), get-vw(30), rem(30));
}
.mb-40 {
    margin-bottom: clamp(rem(20), get-vw(40), rem(40));
}
.mb-50 {
    margin-bottom: clamp(rem(25), get-vw(50), rem(50));
}

.mt-10 {
    margin-top: clamp(rem(5), get-vw(10), rem(10));
}
.mt-20 {
    margin-top: clamp(rem(10), get-vw(20), rem(20));
}
.mt-30 {
    margin-top: clamp(rem(15), get-vw(30), rem(30));
}
.mt-40 {
    margin-top: clamp(rem(20), get-vw(40), rem(40));
}
.mt-50 {
    margin-top: clamp(rem(25), get-vw(50), rem(50));
}

.pb-10 {
    padding-bottom: clamp(rem(5), get-vw(10), rem(10));
}
.pb-20 {
    padding-bottom: clamp(rem(10), get-vw(20), rem(20));
}
.pb-30 {
    padding-bottom: clamp(rem(15), get-vw(30), rem(30));
}
.pb-40 {
    padding-bottom: clamp(rem(20), get-vw(40), rem(40));
}
.pb-50 {
    padding-bottom: clamp(rem(25), get-vw(50), rem(50));
}

.pt-10 {
    padding-top: clamp(rem(5), get-vw(10), rem(10));
}
.pt-20 {
    padding-top: clamp(rem(10), get-vw(20), rem(20));
}
.pt-30 {
    padding-top: clamp(rem(15), get-vw(30), rem(30));
}
.pt-40 {
    padding-top: clamp(rem(20), get-vw(40), rem(40));
}
.pt-50 {
    padding-top: clamp(rem(25), get-vw(50), rem(50));
}
