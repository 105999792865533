@import "../../../assets/scss/partials/mixins";

.new-itinerary-page-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: clamp(rem(470), get-vw(500), rem(500));
  background-color: var(--c-white);
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 27px 22px;

  .header-title {
    font-family: var(--primary-font);
    font-size: 24px;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .create-page-outer {
    .section-outer {
      margin-top: clamp(15px, 15%, 20px);

      .section-header {
        display: flex;
        align-items: center;
        font-family: var(--primary-font);
        justify-content: flex-start;
        gap: 10px;
        padding-bottom: clamp(5px, 10%, 10px);
      }

      .orientation-outer {
        display: flex;
        gap: 20px;

        .orientation {
          font-family: var(--primary-font);
          display: inherit;
          align-items: center;
          width: 100%;
          border: 1px solid #cdcdce66;
          height: 42px;
          padding: 0px 20px;
          border-radius: 10px;
          gap: 12px;
          cursor: pointer;
          transition: all 0.1s linear;

          .horizontal-icon {
            width: 16px;
            height: 10px;
            background-color: black;
            transition: all 0.1s linear;
          }

          .vertical-icon {
            width: 10px;
            height: 16px;
            background-color: black;
            transition: all 0.1s linear;
          }

          &:hover,
          &.active {
            border: 1px solid var(--c-light-green);
          }
        }
      }
    }
  }
  .initializer-button {
    margin-top: 25px;
    background-color: var(--c-orange);
    text-transform: uppercase;
    font-family: var(--primary-font);
    color: var(--c-white);
    font-size: 16px;
    border-radius: 10px;
    padding: 12px;
    transition: all 0.3s linear;
    border: 1px solid var(--c-orange);

    &:hover {
      background-color: var(--c-white);
      color: var(--c-orange);
    }
  }
  &:focus-visible {
    outline: none ;
  }
}
