.fz-32 {
  font-size: clamp(rem(28), get-vw(32), rem(32));
  line-height: 1.2;
}
.fz-24 {
  font-size: clamp(rem(20), get-vw(22), rem(22));
  line-height: 1.2;
}
.fz-20 {
  font-size: clamp(rem(18), get-vw(20), rem(20));
  line-height: 1.2;
}
.fz-18 {
  font-size: clamp(rem(16), get-vw(18), rem(18));
  line-height: 1.2;
}
.fz-16 {
  font-size: clamp(rem(14), get-vw(16), rem(16));
  line-height: 1.2;
}
.fz-14 {
  font-size: clamp(rem(12), get-vw(14), rem(14));
  line-height: 1.2;
}
.fz-12 {
  font-size: rem(12);
  line-height: 1.2;
}
.fz-10 {
  font-size: rem(10);
  line-height: 1.2;
}

.button {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1;
  gap: clamp(rem(10), get-vw(12), rem(12));
  padding: clamp(rem(10), get-vw(15), rem(15)) clamp(rem(15), get-vw(20), rem(20));
  border: 1px solid var(--c-orange);
  border-radius: clamp(rem(7), get-vw(10), rem(10));
  transition: all 0.5s ease;
  .icon {
    svg {
      width: clamp(rem(16), get-vw(20), rem(20));
      height: clamp(rem(16), get-vw(20), rem(20));
    }
  }
  .txt {
    font-size: clamp(rem(14), get-vw(16), rem(16));
    font-family: var(--primary-font);
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    // transform: translateY(clamp(rem(2), get-vw(3), rem(3)));
    color: var(--c-orange);
    --current-color: #e27c37;
    --current-color-rgb: rgb(226, 124, 55);
    transition: all 0.5s ease;
  }
  .icon {
    svg {
      path,
      rect {
        fill: var(--c-orange);
        transition: all 0.5s ease;
      }
    }
  }
  .msg-count {
    padding: 4px 8px;
    background-color: var(--c-orange);
    color: var(--c-white);
    border-radius: 5px;
  }
  &.light-orange-btn {
    background-color: var(--c-light-orange);
  }
  &.orange-btn {
    background-color: var(--c-orange);
    .txt {
      color: var(--c-white);
      --current-color: #fff;
      --current-color-rgb: rgb(255, 255, 255);
    }
    &:hover {
      background-color: var(--c-dark-green);
      border-color: var(--c-dark-green);
      // .txt {
      //   color: var(--c-orange);
      //   --current-color: #e27c37;
      //   --current-color-rgb: rgb(226, 124, 55);
      // }
    }
  }
  &:hover {
    background-color: var(--c-orange);
    .txt {
      color: var(--c-white);
      --current-color: #fff;
      --current-color-rgb: rgb(255, 255, 255);
    }
    .icon {
      svg {
        path,
        rect {
          fill: var(--c-white);
        }
      }
    }
  }
  &.rc-btn{
    padding: clamp(rem(7), get-vw(10), rem(10));
    border-radius: clamp(rem(7), get-vw(10), rem(10));
  }
}

.underline {
  text-decoration: underline;
}

.bdr-10 {
  border-radius: clamp(rem(7), get-vw(10), rem(10));
}

.opacity-4 {
  opacity: 0.4;
}

.mb-40 {
  margin-bottom: clamp(rem(20), get-vw(40), rem(40));
}

.MuiFormLabel-root {
  color: var(--c-black-gray) !important;
  font-family: var(--body-font) !important;
}
.MuiOutlinedInput-root {
  border-color: var(--c-gray) !important;
  font-family: var(--body-font) !important;
}
.MuiInputBase-input {
  color: var(--c-black-gray) !important;
  font-family: var(--body-font) !important;
}

.MuiFormLabel-root.Mui-focused {
  color: var(--c-black-gray) !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--c-black-gray) !important;
  border-width: 1px !important;
}

.MuiSwitch-track {
  background-color: var(--c-gray) !important;
  opacity: 1 !important;
}
.MuiSwitch-switchBase + .MuiSwitch-track {
  &::before {
    display: none;
  }
  &::after {
    display: block;
  }
}
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: var(--c-light-green) !important;
  opacity: 1 !important;
  &::before {
    display: block;
  }
  &::after {
    display: none;
  }
}
.MuiSwitch-root .MuiSwitch-thumb {
  background-color: var(--c-white) !important;
}

.MuiTypography-root.MuiFormControlLabel-label {
  font-family: var(--body-font);
  font-size: clamp(rem(12), get-vw(14), rem(14)) !important;
  line-height: 1.2;
}

.custom-mui-field .MuiInputBase-input {
  font-family: var(--body-font) !important;
  font-size: clamp(rem(12), get-vw(14), rem(14)) !important;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--c-gray);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--c-orange);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--c-dark-green);
}

@-moz-document url-prefix() {
  * {
    scrollbar-color: var(--c-orange) var(--c-gray);
    scrollbar-width: thin;
  }
}

.normal-list.horizontal-icon-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px clamp(rem(20), get-vw(30), rem(30));
  li {
    display: flex;
    align-items: center;
    span.icon {
      line-height: 0;
      margin-right: clamp(rem(7), get-vw(10), rem(10));
    }
    .icon-btn {
      width: clamp(rem(36), get-vw(46), rem(46));
      aspect-ratio: 1/1;
      background-color: var(--c-light-orange);
      display: grid;
      place-items: center;
      border-radius: clamp(rem(5), get-vw(8), rem(8));
      transition: all 0.35s;
      svg {
        width: clamp(rem(16), get-vw(20), rem(20));
        path,
        rect {
          fill: var(--c-orange);
          transition: all 0.35s;
        }
      }
      &:hover {
        background-color: var(--c-orange);
        svg {
          path,
          rect {
            fill: var(--c-white);
          }
        }
      }
    }
  }
}

.MuiTabs-root {
  // margin-bottom: clamp(rem(15), get-vw(20), rem(20));
  margin-bottom: 0;
  .MuiButtonBase-root {
    font-family: var(--primary-font);
    font-size: clamp(0.875rem, 0.8333333333vw, 1rem);
    line-height: 1.2;
    text-transform: uppercase;
    color: var(--c-dark-green);
    --current-color: #134b5f;
    --current-color-rgb: rgb(19, 75, 95);
    padding-top: 0;
    &.Mui-selected {
      color: var(--c-orange);
      --current-color: #e27c37;
      --current-color-rgb: rgb(226, 124, 55);
    }
  }
  .MuiTabs-indicator {
    background-color: var(--c-orange);
  }
}

.MuiTable-root.default-tabel-box {
  .MuiTableRow-root {
    border-left: 0;
    border-right: 0;
  }
  .MuiTableCell-head {
    padding-top: 5px;
    padding-bottom: 5px;
    background: #f5f5f5;
    font-size: 12px;
    text-transform: uppercase;
    font-family: var(--body-font);
    font-weight: 700;
  }
  .MuiTableCell-root {
    border: 0 !important;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: clamp(rem(14), get-vw(16), rem(16));
    font-family: var(--body-font);
  }
  .MuiTableBody-root {
    .MuiTableRow-root {
      &:first-child > * {
        padding-top: 20px;
      }
    }
  }
}
.custom-drawer .MuiPaper-root {
  box-shadow: none;
}
.MuiBox-root {
  border: 0 !important;
}

.label {
  &.small-label {
    padding: 5px 8px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 5px;
    font-size: 12px;
    font-family: var(--primary-font);
    &.complete {
      background-color: var(--c-light-green);
      color: var(--c-dark-green);
    }
    &.in-progress {
      background-color: var(--c-orange);
      color: var(--c-white);
    }
    &.pending {
      background-color: var(--c-yellow);
      color: var(--c-dark-green);
    }
  }
}

.small-btn {
  .button {
    padding: 5px 8px;
    gap: 0 5px;
    background-color: var(--c-light-orange);
    .icon {
      svg {
        width: 12px;
        height: 12px;
      }
    }
    .txt {
      font-size: 12px;
    }
    &:hover {
      background-color: var(--c-orange);
    }
  }
}

.MuiBox-root {
  .button {
    margin-left: 16px;
    margin-top: 10px;
  }
}
.MuiButtonBase-root.MuiAccordionSummary-root {
  padding-left: 0;
  padding-right: 0;
}
.MuiAccordion-root::before {
  display: none !important;
}
.MuiButtonBase-root.MuiAccordionSummary-root {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 52%;
    transform: translateY(-50%);
    width: calc(100% - 35px);
    height: 0.6px;
    content: "";
    opacity: 1;
    background-color: var(--c-dark-green);
    transition:
      opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .MuiTypography-root {
    background-color: var(--c-white);
    padding-right: 15px;
    z-index: 2;
    position: relative;
    font-family: var(--primary-font);
    color: var(--c-dark-green);
    text-transform: uppercase;
  }
}
.MuiAccordionSummary-expandIconWrapper {
  background-color: var(--c-orange);
  border-radius: 50%;

  svg {
    path {
      fill: var(--c-white);
    }
  }
}
.MuiAccordion-root {
  .MuiAccordionDetails-root {
    padding: 0;
  }
}

.MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0;
}

.file-up-img {
  width: clamp(rem(100), get-vw(125), rem(125));
}

.MuiTabs-flexContainer {
  .MuiButtonBase-root {
    // align-items: flex-start;
    &:first-child {
      padding-left: 0;
    }
  }
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin-top: 0 !important;
}

.pac-container {
  z-index: 3000;
  min-width: 20vw;
  max-width: 35vw;
  width: inherit !important;

  // .pac-item {
  //   white-space: unset;
  // }
}
