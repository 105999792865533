@import "../../assets/scss/partials/mixins";

.header-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: clamp(rem(26), get-vw(32), rem(32));
  .lft {
  }
  .rht {
    .rht-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: clamp(rem(14), get-vw(20), rem(20));
      .profile {
        display: flex;
        flex-direction: row;
        align-items: center;
        .profile-img {
          img {
            cursor: pointer;
            width: 50px;
            height: 50px;
            border-radius: 10px;
          }
        }
      }
      .icn {
        img {
          cursor: pointer;
        }
      }
      .notifications {
        position: relative;
        cursor: pointer;
        .notification-num {
          width: 20px;
          height: 20px;
          background-color: var(--c-orange);
          border-radius: 50%;
          border: 2px solid var(--c-white);
          position: absolute;
          top: -5px;
          right: -5px;
          display: grid;
          place-content: center;
          font-size: 10px;
          color: var(--c-white);
          font-weight: 500;
          text-align: center;
          span {
            transform: translateX(-1px);
          }
        }
      }
    }
  }
}
