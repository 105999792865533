.template-card-outer {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(205, 205, 206);
  border-radius: 10.7px;
  transition: all 0.5s;
  width: 302px;
  cursor: pointer;

  .menu-list {
    z-index: 1000;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.625);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 8px;
    top: 8px;

    .menu-icon {
      cursor: pointer;
      color: white;
      height: 20px;
    }
  }

  .template-image {
    width: 300px;
    height: 180px;
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
  }

  .template-content {
    flex: 1;
    padding: 20px;

    .template-name {
      font-size: 18px;
      text-transform: uppercase;
    }

    .data-content {
      display: flex;
      gap: 20px;
      .data-inner {
        font-size: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        text-transform: uppercase;
      }
    }
    .data-content:last-child {
      margin-top: 15px;
    }
  }

  &:hover {
    border: 1px solid var(--c-orange);
  }

  .loading {
    position: absolute;
    left: 120px;
    top: 60px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #ddd;
    border-top-color: var(--c-orange);
    animation: loading 1s linear infinite;
  }
  @keyframes loading {
    to {
      transform: rotate(360deg);
    }
  }
}

.active-card {
  border: 1px solid var(--c-orange);
}
