@import "../../assets/scss/partials/mixins";

.dashboard {
  width: 100%;
  display: flex;
  // flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.content {
  flex: 1 1;
  max-width: 100vw;
  padding: clamp(1.625rem, 1.6666666667vw, 2rem);
  transition: all 0.35s linear;
  width: calc(100vw - 246px);
  height: 100vh;
  display: flex;
  flex-direction: column;

  .inner-content {
    height: 100%;
  }
}
.sidebar.collapsed + .content {
  max-width: calc(100% - 78px);
}

// .sidebar.collapsed + .content {
//   // max-width: calc(100% - 78px);
// }
