@import "../../assets/scss/partials/mixins";

.login-outer {
  display: flex;
  width: 100vw;
  width: 100dvw;
  max-width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  background: linear-gradient(135deg, #134b5f, #105f7b);
  .carousel-outer {
    display: flex;
    align-items: center;
  }
  .lft {
    flex: 0 0 auto;
    width: calc(100% - clamp(rem(550), get-vw(650), rem(650)));
  }
  .rht {
    display: flex;
    flex-direction: column;
    width: clamp(rem(550), get-vw(650), rem(650));
    padding: clamp(rem(60), get-vw(80), rem(80));
    justify-content: space-between;
    background-color: var(--c-white);
    border-radius: 50px 0 0 50px;
    .logo {
      display: block;
      width: clamp(rem(200), get-vw(245), rem(245));
      margin-bottom: clamp(rem(30), get-vw(50), rem(50));
    }
    .title {
      margin-bottom: clamp(rem(20), get-vw(30), rem(30));
    }
    p {
      margin-bottom: clamp(rem(40), get-vw(60), rem(60));
    }
    .login-form {
      margin-bottom: 40px;
      .form-group {
        position: relative;
        .form-control {
          width: 100%;
          height: clamp(rem(46), get-vw(60), rem(60));
          border: 1px solid #cdcdce;
          border-radius: 10px;
          background-color: #fbfbfb;
          outline: transparent;
          box-shadow: none;
        }
        + .form-group {
          margin-top: clamp(rem(20), get-vw(30), rem(30));
        }
        .password-toggle {
          position: absolute;
          width: clamp(rem(46), get-vw(60), rem(60));
          height: clamp(rem(46), get-vw(60), rem(60));
          top: 0;
          right: 0;
          z-index: 2;
          border-radius: 0 10px 10px 0;
          background-color: transparent;
        }
        .dropdown-menu.show {
          display: block;
          position: absolute;
          background-color: white;
          border: 1px solid #ccc;
          width: 100%;
          max-height: 150px;
          overflow-y: auto;
          z-index: 1000;
        }

        .dropdown-item {
          padding: 8px;
          cursor: pointer;
        }

        .dropdown-item:hover {
          background-color: #f1f1f1;
        }
      }
      .sm-msg {
        margin-top: clamp(rem(18), get-vw(25), rem(25));
        cursor: pointer;
        .inner {
          display: flex;
          align-items: center;
          gap: 6px;
        }
      }
      .submit {
        width: 100%;
        height: clamp(rem(46), get-vw(60), rem(60));
        background-color: var(--c-orange);
        border-radius: 10px;
        margin-top: clamp(rem(30), get-vw(50), rem(50));
        transition: all 0.35s;
        &:hover {
          background-color: var(--c-dark-green);
        }
      }
    }
  }
}
