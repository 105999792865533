.drop-button {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    line-height: 1;
    gap: 5px;
    padding: 20px;
    // border: 1px solid var(--c-orange);
    border-radius: 10px !important;

    // transition: all 0.5s ease;
    .icon {
        svg {
            width: 16px;
            height: 16px;
        }
    }

    .txt {
        font-size: 12px;
        font-family: var(--primary-font);
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1;
        // transform: translateY(clamp(rem(2), vw(3), rem(3)));
        color: black;
        --current-color: #000000;
        --current-color-rgb: rgb(0, 0, 0);
        // transition: all 0.5s ease;
    }
}