.carousel-inner {
    display: flex;
    justify-content: center;

    .carousal-content {
        padding: 0px 10px;

        .image-content {
            img {
                width: 100%;
            }
        }

        .text-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: white;
            margin-top: clamp(60px, 5vh + 40px, 100px);
            p {
                margin-top: 10px;
                text-align: center;
            }
        }
    }
}
