.small-screen-message{
    position: fixed;
    z-index: 9999;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    background-color: #134B5F;
    display: none;
    place-content: center;
    .txt{
        width: 100%;
        max-width: 800px;
        padding: 0 30px;
        text-align: center;
        .logo{
            margin-bottom: 20px;
        }
        h4{
            font-size: 26px;
            color: var(--c-orange);
            font-weight: 300;
            font-family: var(--primary-font);
            letter-spacing: 1px;
            text-transform: uppercase;
        }
        p{
            font-size: 18px;
            color: #fff;
            font-weight: 300;
            font-family: var(--primary-font);
            letter-spacing: .5px;
            padding: 0 50px;
        }
    }
}

@media (max-width: 1099px) {

    .small-screen-message{
        display: grid;
    }
    .main{
        display: none;
    }

}