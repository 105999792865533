@import "../../assets/scss/partials/mixins";

.otp-verification-form {
    display: flex;
    flex-direction: column;
    align-items: center;


    .form-group {
        margin-bottom: 20px;

        input {
            width: clamp(2rem, 6vw, 3.125rem) !important;
            height: clamp(2rem, 6vw, 3.125rem);
            margin: 0 6px;
            font-size: 1.5rem;
            text-align: center;
            border-radius: 10px;
            border: 1px solid #ccc;
        }
    }



    .resend-otp {
        text-align: left;
        width: 100%;

        p {
            font-size: 1rem;
            color: rgba(142, 142, 147, 1);

            strong {
                color: var(--c-orange);
            }

            span {
                cursor: pointer;
                color: black;
                font-family: var(--primary-font);
                size: 14px;
                font-weight: 400;
            }
        }
    }

    .submit {
        background-color: #007bff;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: white;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}