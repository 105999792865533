@import "../../assets/scss/partials/mixins";

.insert-image-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: clamp(rem(680), get-vw(700), rem(700));
  height: 400px;
  background-color: var(--c-white);
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 17px 14px;
  display: flex;
  flex-direction: column;

  .header-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-title {
      font-family: var(--primary-font);
      font-size: 18px;
    }
  }

  .form-content {
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    overflow-y: auto;
    padding: 5px 0px;
  }

  .footer-outer {
    display: flex;
    justify-content: flex-end;
  }

  &:focus {
    outline: none;
  }
}
