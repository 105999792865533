@import "../../../assets/scss/partials/mixins";

.template-preview-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  max-width: clamp(rem(1280), get-vw(1200), rem(1200));
  top: 50%;
  left: 50%;
  width: 85vw;
  height: 70vh;
  background-color: var(--c-white);
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 17px;

  .close-icon {
    position: absolute;
    right: 10px;
    top: 12px;
  }

  .pdf-viewer {
    max-width: 880px;
    width: 60vw;
    border: 1px solid #cdcdcd66;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-family: "Glancyr";
    background-color: var(--c-gray);
    object-fit:contain;
  }

  .template-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 30px 0px 30px;
    text-transform: uppercase;
    font-family: "Glancyr";

    .template-name {
      font-size: 26px;
      font-weight: 300;
      margin-bottom: 30px;
    }

    .data-inner {
      font-size: 15px;
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .data-inner + .data-inner {
      margin-top: 10px;
    }
  }

  .button-outer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .delete-template {
      text-transform: uppercase;
      color: #ea4335;
      font-size: 14px;
    }
  }

  embed {
    border-radius: 10px;
  }

  &:focus {
    outline: none;
  }
}
