@import "../../../assets/scss/partials/mixins";
.library-container {
    display: flex;
    flex-direction: column;

    .content-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .lft {
            .lft-inner {
                .view-styles {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: clamp(rem(10), get-vw(15), rem(15)) clamp(rem(15), get-vw(20), rem(20));
                    background-color: rgba(205, 205, 206, 0.2);
                    gap: clamp(rem(20), get-vw(28), rem(28));
                    position: relative;
                }
            }
        }

        .content-right {
            display: flex;
            gap: 14px;

            .filter-outer {
                border-radius: clamp(0.4375rem, 0.5208333333vw, 0.625rem);
                border: 1px solid var(--c-white-gray);
                padding: clamp(0.625rem, 0.78125vw, 0.9375rem) clamp(0.9375rem, 1.0416666667vw, 1.25rem);

                svg {
                    cursor: pointer;
                }

                span:last-child {
                    margin-left: 24px;
                }
            }
        }
    }

    .library-outer {
        display: flex;
        margin-top: 18px;
        height: calc(100vh - 186px);
        font-family: var(--primary-font);

        .template-container {
            flex: 1;
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: flex-start;
            gap: 10px;
            overflow-y: auto;
        }
    }
}
