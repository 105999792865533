// Some people like naming based on where the color
// will be used while others prefer names that
// represent the color

// $primary-font: ;
// $body-font: ;


/*Importing Fonts*/

@font-face {
    font-family: 'Glancyr';
    src: url('../../fonts/Glancyr/Glancyr-Medium.eot');
    src: url('../../fonts/Glancyr/Glancyr-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Glancyr/Glancyr-Medium.woff2') format('woff2'),
        url('../../fonts/Glancyr/Glancyr-Medium.woff') format('woff'),
        url('../../fonts/Glancyr/Glancyr-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Glancyr';
    src: url('../../fonts/Glancyr/Glancyr-Regular.eot');
    src: url('../../fonts/Glancyr/Glancyr-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Glancyr/Glancyr-Regular.woff2') format('woff2'),
        url('../../fonts/Glancyr/Glancyr-Regular.woff') format('woff'),
        url('../../fonts/Glancyr/Glancyr-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Glancyr';
    src: url('../../fonts/Glancyr/Glancyr-Light.eot');
    src: url('../../fonts/Glancyr/Glancyr-Light.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Glancyr/Glancyr-Light.woff2') format('woff2'),
        url('../../fonts/Glancyr/Glancyr-Light.woff') format('woff'),
        url('../../fonts/Glancyr/Glancyr-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Glancyr';
    src: url('../../fonts/Glancyr/Glancyr-Bold.eot');
    src: url('../../fonts/Glancyr/Glancyr-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Glancyr/Glancyr-Bold.woff2') format('woff2'),
        url('../../fonts/Glancyr/Glancyr-Bold.woff') format('woff'),
        url('../../fonts/Glancyr/Glancyr-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


:root {
    --c-black: #000;
    --c-black-gray: #251C15;
    
    --c-white: #fff;
    --c-white-gray: #F5F5F5;
    --c-light-green: #75D2B8;
    --c-dark-green: #134B5F;
    --c-orange: #E27C37;
    --c-light-orange: #FFEDE2;
    --c-yellow: #FFD600;
    --c-gray: #CDCDCE;
    --primary-font: "Glancyr", sans-serif;
    --body-font: "Sen", sans-serif;
}

$nlp: 0.3px;
$bc: center;
$bgnrp: no-repeat;
$bgcover: cover;
$transition: all 0.35s;
$c-black: var(--c-black);
$c-white: var(--c-white);
$c-black-gray:  var(--c-black-gray);
$primary-font: var(--primary-font);
$body-font: var(--body-font);

//**Color classes**//

.c-black {
    color: var(--c-black);
    --current-color: #000;
    --current-color-rgb: rgb(0, 0, 0);
}
.c-black-gray {
    color: var(--c-black-gray);
    --current-color: #251C15;
    --current-color-rgb: rgb(37, 28, 21);
}
.c-white {
    color: var(--c-white);
    --current-color: #fff;
    --current-color-rgb: rgb(255, 255, 255);
}
.c-light-green {
    color: var(--c-light-green);
    --current-color: #75D2B8;
    --current-color-rgb:rgb(117, 210, 184);
}
.c-dark-green {
    color: var(--c-dark-green);
    --current-color: #134B5F;
    --current-color-rgb: rgb(19, 75, 95);
}
.c-orange {
    color: var(--c-orange);
    --current-color: #E27C37;
    --current-color-rgb: rgb(226, 124, 55);
}
.c-light-orange {
    color: var(--c-light-orange);
    --current-color: #FFEDE2;
    --current-color-rgb: rgb(255, 237, 226);
}
.c-yellow {
    color: var(--c-yellow);
    --current-color: #FFD600;
    --current-color-rgb: rgb(255, 214, 0);
}
.c-gray {
    color: var(--c-gray);
    --current-color: #CDCDCE;
    --current-color-rgb: rgb(205, 205, 206);
}



//**Font family classes**//

.primary-font {
    font-family: $primary-font;
}

.body-font {
    font-family: $body-font;
}

//**Font weight classes**//

.fw-900 {
    font-weight: 900;
}
.fw-800 {
    font-weight: 800;
}
.fw-700 {
    font-weight: 700;
}
.fw-600 {
    font-weight: 600;
}
.fw-500 {
    font-weight: 500;
}
.fw-400 {
    font-weight: 400;
}
.fw-300 {
    font-weight: 300;
}
.fw-200 {
    font-weight: 200;
}
.fw-100 {
    font-weight: 100;
}



//**helper classes**//

.flex-0-a{
    flex: 0 0 auto;
}
.list-s-none{
    list-style: none;
}
.ttu{
    text-transform: uppercase;
}
.db{
    display: block;
}
.normal-list{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}
.item-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
