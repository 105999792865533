.builder-container {
    height: 100%;
}

.go3144864827 {
    width: 0px;
}

.bp5-divider {
    width: 0px;
    height: 0px !important;
    padding: 0px;
    margin: 0px 2px !important;
}
