@import "../../assets/scss/partials/mixins";

.sidebar {
  max-width: clamp(rem(200), get-vw(270), rem(270));
  background-color: var(--c-dark-green);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transition: 0.35s linear;
  .sidebar-inner {
    display: flex;
    height: 100vh;
    height: 100dvh;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;
    padding: clamp(rem(26), get-vw(32), rem(32));
    overflow-y: auto;

    .top {
      .logo {
        width: 100%;
        padding-bottom: clamp(rem(26), get-vw(32), rem(32));
        margin-bottom: clamp(rem(26), get-vw(32), rem(32));
        border-bottom: 1px solid rgba(245, 227, 215, 0.2);
        .logo-inner {
          max-width: 150px;
          display: flex;
          overflow: hidden;
          justify-content: flex-end;
          transition: all 0.35s linear;
        }
        .logo-img {
          display: block;
        }
      }

      .nav-vertical {
        .main-manu {
          padding-left: 0;
          margin-bottom: 0;
          .main-manu-item {
            .main-manu-link {
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              line-height: 1;
              gap: clamp(rem(10), get-vw(12), rem(12));
              .icon {
                svg {
                  width: clamp(rem(16), get-vw(20), rem(20));
                  height: clamp(rem(16), get-vw(20), rem(20));
                }
              }
              .txt {
                font-size: clamp(rem(14), get-vw(16), rem(16));
                font-family: var(--primary-font);
                text-transform: uppercase;
                line-height: 1;
                // transform: translateY(clamp(rem(2), get-vw(3), rem(3)));
                color: var(--c-white);
                --current-color: #fff;
                --current-color-rgb: rgb(255, 255, 255);
                transition: all 0.35s linear;
                max-width: 120px;
                overflow: hidden;
                display: block;
                white-space: nowrap;
              }
              .icon {
                svg {
                  path,
                  rect {
                    fill: var(--c-white);
                    transition: all 0.5s ease;
                  }
                }
              }
              .msg-count {
                padding: 4px 8px;
                background-color: var(--c-orange);
                color: var(--c-white);
                border-radius: 5px;
              }
              &:hover,
              &.active {
                .txt {
                  color: var(--c-orange);
                  --current-color: #e27c37;
                  --current-color-rgb: rgb(226, 124, 55);
                }
                .icon {
                  svg {
                    path,
                    rect {
                      fill: var(--c-orange);
                    }
                  }
                }
              }
            }
            + .main-manu-item {
              margin-top: clamp(rem(20), get-vw(28), rem(28));
            }
            .child-list {
              padding-left: clamp(rem(26), get-vw(32), rem(32));
              padding-top: clamp(rem(22), get-vw(28), rem(28));
              padding-bottom: clamp(rem(5), get-vw(10), rem(10));
              position: relative;
              &::after {
                content: "";
                height: calc(100% - (clamp(rem(22), get-vw(28), rem(28)) + clamp(rem(5), get-vw(10), rem(10))));
                width: 2px;
                background-color: var(--c-light-green);
                position: absolute;
                top: calc(clamp(rem(22), get-vw(28), rem(28)) / 2);
                left: clamp(rem(9), get-vw(11), rem(11));
              }
              .child-list-item {
                .child-list-link {
                  font-size: clamp(rem(12.8), get-vw(14), rem(14));
                  font-family: var(--primary-font);
                  text-transform: uppercase;
                  color: var(--c-white);
                  --current-color: #fff;
                  --current-color-rgb: rgb(255, 255, 255);
                  transition: all 0.5s ease;
                  position: relative;

                  .txt-item {
                    transition: all 0.35s linear;
                    max-width: 120px;
                    overflow: hidden;
                    display: block;
                    white-space: nowrap;
                  }
                  &::after {
                    content: "";
                    height: 2px;
                    width: clamp(rem(10), get-vw(12), rem(12));
                    background-color: var(--c-light-green);
                    position: absolute;
                    left: clamp(rem(-21), get-vw(-21), rem(-15));
                    top: 7px;
                  }
                  &:hover {
                    color: var(--c-orange);
                    --current-color: #e27c37;
                    --current-color-rgb: rgb(226, 124, 55);
                  }
                  &.active {
                    color: var(--c-light-green);
                    --current-color: #75d2b8;
                    --current-color-rgb: rgb(117, 210, 184);
                  }
                }
                + .child-list-item {
                  padding-top: clamp(rem(20), get-vw(28), rem(28));
                }

                .mac-child-list-link {
                  &::after {
                    top: -13px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .bottom {
      .toggle-inner {
        display: flex;
        justify-content: end;
        padding-bottom: clamp(rem(20), get-vw(26), rem(26));

        .image-icon {
          color: var(--c-white);
        }
      }
      .bottom-inner {
        padding-top: clamp(rem(26), get-vw(32), rem(32));
        border-top: 1px solid rgba(245, 227, 215, 0.2);
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-end;
        .lft {
          max-width: calc(100% - clamp(rem(16), get-vw(20), rem(20)));
          padding-right: 10px;
          overflow: hidden;
          transition: all 0.5s linear;
          .name {
            margin-bottom: clamp(rem(3), get-vw(5), rem(5));
          }
        }
        .rht {
          width: clamp(rem(16), get-vw(20), rem(20));
          .icon {
            cursor: pointer;
            svg {
              width: clamp(rem(16), get-vw(20), rem(20));
              height: clamp(rem(16), get-vw(20), rem(20));
            }
          }
        }
      }
    }
  }
  &.collapsed {
    max-width: 90px;
    .sidebar-inner .top .logo .logo-inner {
      max-width: 25px;
    }
    .sidebar-inner .top .nav-vertical .main-manu .main-manu-item .main-manu-link .txt {
      max-width: 0px;
    }
    .sidebar-inner
      .top
      .nav-vertical
      .main-manu
      .main-manu-item
      .child-list
      .child-list-item
      .child-list-link
      .txt-item {
      max-width: 0px;
    }
    .sidebar-inner .bottom .bottom-inner .lft {
      max-width: 0px;
      padding: 0;
    }
  }
}
