.template-outer {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    button {
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        box-shadow: 0 0 5px rgba(16, 22, 26, 0.3);
        max-width: 130px;
        max-height: 130px;
        width: 130px;
        height: 130px;
        background-color: var(--c-gray);
        position: relative;
        color: var(--c-orange);

        img {
            width: 100%;
            height: 100%;
            cursor: pointer;
            display: block;
            min-height: 50px;
            object-fit: cover;
        }
    }
}
