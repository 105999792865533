@import "../../assets/scss/partials/mixins";

.transparent-btn {
  display: flex;
  align-items: center;
  line-height: 1;
  gap: 6px;
  .icon {
    svg {
      width: clamp(rem(16), get-vw(20), rem(20));
      height: clamp(rem(16), get-vw(20), rem(20));
      path,
      rect {
        fill: var(--c-dark-green);
        transition: all 0.5s ease;
      }
    }
  }
  .txt {
    line-height: 0;
    color: var(--c-dark-green);
    --current-color: #134b5f;
    --current-color-rgb: rgb(19, 75, 95);
    transition: all 0.5s ease;
    white-space: nowrap;
  }
  &.btn-1 {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      right: clamp(rem(-14), get-vw(-14), rem(-10));
      top: 0;
      background-color: var(--c-gray);
    }
  }
  &:hover,
  &.active {
    .icon {
      svg {
        path,
        rect {
          fill: var(--c-orange);
        }
      }
    }
    .txt {
      color: var(--c-orange);
      --current-color: #e27c37;
      --current-color-rgb: rgb(226, 124, 55);
    }
  }
}
