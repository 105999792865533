.custom-mui-field {
    margin: 0px !important;

    .MuiFormLabel-root {
        font-size: 14px;
        font-weight: 400;
        font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    }

    .MuiOutlinedInput-notchedOutline {
        border-radius: 10px;
    }

    .MuiInputBase-input {
        font-size: 14px;
        font-weight: 400;
        font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    }

    .MuiFormHelperText-root {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 0px;
        font-size: 10px;
        font-weight: 400;
        font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    }
}
