.custom-multi-select {
    .MuiSvgIcon-root {
        width: 0.7em;
        height: 0.7em;
    }

    .MuiAutocomplete-tag {
        margin: 1px !important;
        margin-right: 2px !important;
    }

    .MuiChip-root {
        background-color: rgb(245, 227, 215);
        height: max-content;

        .MuiSvgIcon-root {
            width: 0.8em;
            height: 0.8em;
            color: rgb(226, 124, 55);
        }
    }

    .MuiChip-label {
        font-size: 12px;
        font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
    }
}
