@import "../../assets/scss/partials/mixins";

.exp-modal-inner.notifications-inner {
  width: 800px;
  .mark-read{
    transition: all .35s;
    &:hover{
        color: var(--c-dark-green);
    }
  }

  .middle{
    overflow-y: auto;
    height: calc(100vh - clamp(rem(180), get-vw(270), rem(270)));
    padding-right: 10px;
  }

  .notification-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    background-color: #FAFAFA;
    border-radius: 10px;
    cursor: pointer;
    &.unread{
        background-color: rgba(19, 75, 95, 0.1);
    }
    .icon{
        flex: 0 0 auto;
        width: 50px;
        height: 50px;
        display: grid;
        place-content: center;
        background-color: rgba(19, 75, 95, 0.1);
        border: 1px solid var(--c-dark-green);
        border-radius: 10px;
    }
    .txt {
        flex: 0 0 auto;
        width: calc(100% - 66px);
      .title {
        margin-bottom: 2px;
      }
      .des {
        margin-bottom: 2px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }
    + .notification-item {
        margin-top: 10px;
    }
  }
}
